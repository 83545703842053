.cards {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 500px;
    margin: 25px;
    padding: 25px;
    justify-content: center;
    align-items: center;
    background-color: #843E70;
    color: white;
    border-radius: 20px 0px 20px 20px ;
    box-shadow: 20px 20px 0px rgb(252, 219, 238);
}

.containerTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.cards p {
    font-size: 1.5rem;
}

.cards h3 {
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-size: 1.1rem;
}

@media (min-width: 800px) and (max-width: 1100px) {
    .cards h3 {
        font-size: 1.5rem;
    }
}

@media (min-width: 500px) and (max-width: 799px){
    .cards {
        margin: auto;
    }
    
    .cards h3 {
        font-size: 1rem;
    }
}

@media (min-width: 300px) and (max-width: 499px) {
    .cards {
        height: 500px;
        line-height: 18px;
    }
}