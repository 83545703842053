.btn2 {
    background-color: rgb(252, 219, 238);
    border: none;
    padding: 15px;
    color: #843E70;
    border-radius: 50px;
    font-size: 1.3rem;
    transition: 0.5s ease;
    margin-top: 10px;
}

.btn2:hover {
    transform: translateY(-5px);
    cursor: pointer;
    text-decoration: underline;
}