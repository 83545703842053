.btn {
    background-color: #843E70;
    border: none;
    padding: 15px;
    color: white;
    border-radius: 50px;
    font-size: 1.3rem;
    transition: 0.5s ease;
}

.btn:hover {
    transform: translateY(-5px);
    cursor: pointer;
    text-decoration: underline;
}