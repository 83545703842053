.testmonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.cardsPacients {
    display: flex;
    justify-content: center;
    width: 90%;
    height: 600px;
}

@media (min-width: 501px) and (max-width: 1100px) {
    .testmonials h2 {
        margin-top: 500px;
        margin-bottom: 350px;
    }

    .testmonials .cardsPacients{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 150px;
    }
    
}

@media (max-width: 500px) {

    .testmonials h2 {
        margin-top: 10px;
        margin-bottom: 500px;
        display: flex;
        align-items: center;
    }

    .testmonials .cardsPacients{
        margin-bottom: 500px;
        display: flex;
        align-items: center;
        width: 90%;
        flex-direction: column;
    }
}