.contact {
    display: flex;
    margin-top: 100px;
}

.contactLeft {
    background-color: #843E70;
    color: white;
    width: 40%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
}

.contact a {
    color: rgb(252, 219, 238);
}

.contact svg {
    margin-top: 30px;
}

.pagamento {
  display: flex;

}

.pagamento img {
  width: 100px;
  margin-right: 35px;
}

.form {
    display: flex;
    justify-content: center;
    width: 60%;
    align-items: center;
    flex-direction: column;
    background-color: rgb(252, 219, 238);
    height: 100vh;
    color: #843E70;
    text-align: left;
  }

.form p {
    margin-top: 100px;
    text-align: left;
    color: #843E70;
}

  .form a {
    color: #843E70;
    text-decoration: none;
    font-weight: bold;
  }
  
  .input{
    margin-bottom: 14px;
    height: 80px;
    border-radius: 50px;
    border: 0;
    padding: 0px 20px;
    width: 500px;
  }
  
  .textarea{
    border-radius: 20px;
    border: 0;
    width: 500px;
    padding: 10px 20px;
    height: 400px;
    resize: none;
    padding-bottom: 0px;
  }
  
  .button{
    color: rgb(252, 219, 238);
    border: none;
    padding: 15px;
    background-color: #843E70;
    border-radius: 50px;
    font-size: 1.3rem;
    transition: 0.5s ease;
    margin-top: 25px;
  }
  
  .button:hover{
    transform: translateY(-5px);
    cursor: pointer;
    text-decoration: underline;
  }

  .rodape p {
    text-align: center;
  }

  @media (min-width: 700px) and (max-width: 1100px) {
    .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 550px;
    }

    .contactLeft {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60vh;
      text-align: center;
    }

    .contactLeft p {
      font-size: 1.5rem;
    }

    .contact svg {
      margin-bottom: -70px;
    }

    .form {
      width: 100%;
    }

    .button {
      width: 250px;
      font-size: 1.5rem;
    }

    input {
      font-size: 1.5rem;
    }

    .textarea {
      font-size: 1.5rem;
    }

    .rodape p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      text-align: center;
      flex-direction: column;
    }

    
  }

  @media (min-width: 500px) and (max-width: 699px){
    .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 100px;
    }

    .contactLeft {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60vh;
      text-align: center;
    }

    .contact svg {
      margin-bottom: -100px;
    }

    .form {
      text-align: center;
      width: 100%;
    }

    .input {
      font-size: 1.2rem;
      width: 280px;
    }
    
    .textarea {
      font-size: 1.2rem;
      width: 280px;
    }

    .button {
      width: 250px;
      font-size: 1.5rem;
    }

    .pagamento {
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 300px) and (max-width: 499px) {
    .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 100px;
    }

    .contactLeft {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 120vh;
      text-align: center;
    }

    .form {
      text-align: center;
      width: 100%;
      height: 130vh;
    }

    .input {
      font-size: 1.2rem;
      width: 280px;
    }
    
    .textarea {
      font-size: 1.2rem;
      width: 280px;
    }

    .button {
      width: 250px;
      font-size: 1.5rem;
    }

    .rodape {
      margin-bottom: 70px;
      text-align: center;
    }

    .form h1 {
      font-size: 1.5rem;
    }
}