.presentation {
    display: flex;
    justify-content: space-between;
    background-color: rgb(252, 219, 238);
    color: #843E70;
    
}

.right p {
    font-size: 1.3rem;
}

.presentation .atendimento {
    display: flex;
    height: 510px;
}

.presentation .right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding: 15px;
}

.btnWts {
    text-align: right;
}

@media (min-width: 501px) and (max-width: 1100px) {
    .presentation {
        display: flex;
        flex-direction: column;
    }

    .atendimento img {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .presentation {
        display: flex;
        flex-direction: column;
    }

    .atendimento img {
        width: 100%;
        height: 250px;
    }

    .right p {
        margin-top: -250px;
    }
}