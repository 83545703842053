@import url('https://fonts.googleapis.com/css2?family=Playwrite+AU+QLD:wght@100..400&display=swap');

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #843E70;
    color: white;
    width: 100%;
}

.navigation .title {
    font-family: "Playwrite AU QLD";
    font-weight: normal;
}

.navigation span {
    font-weight: bold;
}

.navigation h3 {
    margin-left: 100px;
}

.navigation ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-right: 100px;
}

.navigation li {
    list-style: none;
}

.navigation a {
    text-decoration: none;
    font-weight: bold;
    color: white;
}

@media (min-width: 501px) and (max-width: 1100px) {
    .navigation ul {
        display: none;
    }

    .navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .navigation h3 {
        margin-left: 0;
    }
}

@media (max-width: 500px) {
    .navigation ul {
        display: none;
    }

}

@media (min-width: 300px) and (max-width: 499px) {
    .navigation ul {
        display: none;
    }

    .navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .navigation h3 {
        margin-left: 0;
    }
}
