.about {
    margin-top: 100px;
}

.infoAtendimento {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(252, 219, 238);
    width: 95%;
    box-shadow: 1px 1px 5px black;
    margin: auto;
    height: 300px;
    border-radius: 0px 50px 0px 50px;
}

.infoAtendimento .image2 {
    display: none;
}

.infoAtendimento p {
    text-align: left;
    width: 570px;
    font-size: 1.3em;
    line-height: 1.4em;
}

.infoAtendimento img{
    border-radius: 50px 0px 50px 0px;
    width: 300px;
    box-shadow: -20px 20px 0px #843E70;
}

.botao {
    margin: 25px;
}

@media (min-width: 501px) and (max-width: 1100px) {
    .infoAtendimento {
        display: flex;
        flex-direction: column;
    }

    .about h2 {
        margin-top: 600px;
    }

    .infoAtendimento p {
        width: 80%;
        height: auto;
        margin-top: 50px;
    }

    .infoAtendimento .image1 {
        display: none;
    }

    .infoAtendimento .image2 {
        display: flex;
        margin-top: 155px;
    }
}

@media (max-width: 500px) {
    .infoAtendimento {
        display: flex;
        flex-direction: column;
        height: 1000px;
    }

    .infoAtendimento p {
        width: 80%;
        height: auto;
        font-size: 1.3rem;
    }

    .infoAtendimento img{
        margin: auto;
        width: 70%;
    }
}

@media (min-width: 300px) and (max-width: 499px) {
    .about h2 {
        margin-top: 50px;
    }
}
