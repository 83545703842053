.navigationMobile {
    display: none;
}

@media (min-width: 700px) and (max-width: 1100px) {
    .navigationMobile {
        bottom: 0;
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        font-size: 0.7rem;
        font-weight: bold;
        background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    }

    .navigationMobile ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: auto;
        gap: 100px;
        font-size: 1.3rem;
        position: 0;
    }

    .navigationMobile a {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #843E70;
        text-decoration: none;
        flex-direction: column;
        
    }
}

@media (min-width: 300px) and (max-width: 699px){ 
    .navigationMobile {
        bottom: 0;
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        font-weight: bold;
        background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    }

    .navigationMobile ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: auto;
        gap: 40px;
        font-size: 0.8rem;
        position: 0;
    }

    .navigationMobile a {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #843E70;
        text-decoration: none;
        flex-direction: column;
    }
}
