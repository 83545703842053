.about {
    margin-top: 100px;
}

.aboutMe {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(252, 219, 238);
    width: 95%;
    box-shadow: 1px -1px 5px black;
    margin: auto;
    height: 350px;
    border-radius: 0px 50px 0px 50px;
}

.aboutMe p {
    text-align: left;
    width: 570px;
    font-size: 1.3em;
    line-height: 1.4em;

}

.aboutMe img {
    border-radius: 0px 50px 0px 50px;
    width: 300px;
    box-shadow: 20px 20px 0px #843E70;
}



@media (min-width: 700px) and (max-width: 1100px) {
    .aboutMe {
        display: flex;
        flex-direction: column;
    }

    .aboutMe p {
        width: 90%;
    }

    .aboutMe img {
        margin-top: 100px;
    }
}

@media (min-width: 500px) and (max-width: 699px){
    .aboutMe {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .about .parte2 {
        display: none;
    }

    .aboutMe p {
        width: 80%;
        height: auto;
        font-size: 1.3rem;
    }

    .aboutMe img {
        margin: auto;
        width: 70%;
    }
}

@media (min-width: 300px) and (max-width: 499px) {
    .aboutMe {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .about .parte2 {
        display: none;
    }

    .aboutMe p {
        width: 80%;
        height: auto;
        font-size: 1.3rem;
        margin-bottom: -20px;
    }

    .about .aboutMe {
        height: 780px;
    }

    .aboutMe img {
        margin: auto;
        width: 70%;
    }
}